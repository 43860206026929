@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --line-color: #e5e7eb;
}

html.dark {
  --line-color: rgba(255, 255, 255, 0.2);
}

.borderBlack {
  @apply border border-black/10;
}
